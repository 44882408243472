<template>
  <div class="container">
    <div class="card card-custom">
      <!--begin::Create-->
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">{{ $t('withdraw.name') }}</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row justify-content-start px-8 px-lg-10">
          <div class="col-md-7 col-lg-7 col-sm-12 justify-content-start mb-5">
            <!--begin::Form Wizard-->
            <ValidationObserver v-slot="{ handleSubmit }">
              <form class="form">
                <!--begin::Step 1-->
                <div data-wizard-type="step-content" data-wizard-state="current">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group row">
                        <label class="col-12 col-form-label">{{ $t('withdraw.select_withdrawal_type') }}</label>
                        <div class="col-12">
                          <validation-provider
                            rules="required"
                            name="Currency"
                            v-slot="{ classes, errors }"
                          >
                            <multiselect
                              v-model="selectedCurrency"
                              :options="currencies"
                              :internal-search="true"
                              :loading="currenciesLoading"
                              @input="onChangeCurrency"
                              :allow-empty="false"
                              open-direction="bottom"
                              deselect-label=""
                              select-label=""
                              selected-label=""
                              track-by="name"
                              label="name"
                              :placeholder="$t('finance.currency')"
                              name="Currency"
                              :class="classes"
                              :disabled="approveWithdraw"
                            >
                              <template slot="singleLabel" slot-scope="{ option }">{{
                                option.name
                              }}</template>
                              <template slot="option" slot-scope="{ option }">{{
                                option.name
                              }}</template>
                              <template slot="noOptions">{{
                                "List is empty"
                              }}</template>
                            </multiselect>
                            <span class="error__message">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-12 justify-content-around">
                          <label class="col-form-label">{{ bankWalletLabel }}</label>
                          <label v-if="!approveWithdraw" class="col-form-label float-right text-primary hover-opacity-60 cursor-pointer" @click.prevent="showCreateWalletModal">
                            {{ createWalletLabel }}
                          </label>
                        </div>
                        <div class="col-12">
                          <validation-provider
                            rules="required"
                            :name="bankWalletLabel"
                            v-slot="{ classes, errors }"
                          >
                            <multiselect
                              v-model="selectedProvider"
                              :options="providers"
                              :internal-search="true"
                              @input="onChangeProvider"
                              :loading="providersLoading"
                              open-direction="bottom"
                              deselect-label=""
                              select-label=""
                              selected-label=""
                              track-by="label"
                              label="label"
                              :placeholder="bankWalletLabel"
                              :class="classes"
                              :disabled="approveWithdraw"
                            >
                              <template slot="singleLabel" slot-scope="{ option }">{{
                                option.label
                              }}</template>
                              <template slot="option" slot-scope="{ option }">{{
                                option.label
                              }}</template>
                              <template slot="noOptions">{{
                                "List is empty"
                              }}</template>
                            </multiselect>
                            <span class="error__message">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-lg-12 col-sm-12 col-form-label">{{ $t('finance.amount') }}</label>
                        <div class="col-lg-9 col-sm-12">
                          <validation-provider
                            rules="required"
                            name="Amount"
                            v-slot="{ classes, errors }"
                          >
                            <currency-input
                              class="form-control"
                              :placeholder="$t('finance.amount')"
                              :class="classes"
                              :precision="currencyPrecision"
                              v-model="amount"
                              :disabled="approveWithdraw || !selectedProvider"
                            />
                            <span class="error__message">{{ errors[0] }}</span>
                          </validation-provider>
                        </div>
                        <div class="col-lg-3 col-sm-12 d-flex flex-column align-items-end">
                          <span>{{ $t('finance.available_limit') }}</span>
                          <span v-if="!balanceLoading">{{ balanceByCurrency }} </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--begin::Actions-->
                <div class="d-flex justify-content-center pt-10" v-if="!approveWithdraw">
                  <form-submit-btn
                    :btnClass="'btn btn-pill btn-primary w-75'"
                    :isSubmitting="isSubmitting"
                    @click="handleSubmit(_handleContinue)"
                    :text="$t('commons.continue')"
                  />
                </div>
                <div class="col-12" v-else>
                  <div class="row justify-content-center align-items-center col-12 mb-2 row">
                    <span class="font-weight-normal" style="width: 45%">{{ amountOrQuantity }}<small class="mr-2">({{selectedCurrency.code}})</small></span>
                    <span style="width: 5%">:</span>
                    <span style="width: 50%"><b>{{ confirmData.amount }} {{ selectedCurrency.code }}</b></span>
                  </div>
                  <div class="row justify-content-center align-items-center col-12 mb-2 row">
                    <span class="font-weight-normal" style="width: 45%">{{ $t('finance.fee') }}<small class="mr-2">({{selectedCurrency.code}})</small></span>
                    <span style="width: 5%">:</span>
                    <span style="width: 50%"><b>{{ confirmData.fee }} {{ selectedCurrency.code }}</b></span>
                  </div>
                  <div class="row justify-content-center align-items-center col-12 mb-2 row">
                    <span class="font-weight-normal" style="width: 45%">{{ $t('finance.amount_to_pay') }}<small class="mr-2">({{selectedCurrency.code}})</small></span>
                    <span style="width: 5%">:</span>
                    <span style="width: 50%"><b>{{ confirmData.amountToPay }} {{ selectedCurrency.code }}</b></span>
                  </div>
                  <div class="col-12 mt-10 mb-2">
                    <div class="col-12 text-center mt-2">
                      <form-submit-btn
                        :btnClass="'btn btn-pill btn-success w-75'"
                        :isSubmitting="isSubmitting"
                        @click="handleApproveWithdraw"
                        :text="$t('commons.approve')"
                      />
                    </div>
                    <div class="col-12 text-center mt-2">
                      <form-submit-btn
                        :btnClass="'btn btn-pill btn-secondary px-15 py-3 w-75'"
                        @click="handleCancelWithdraw"
                        :text="$t('commons.cancel')"
                      />
                    </div>
                  </div>
                </div>
                <!--end::Actions-->
              </form>
            </ValidationObserver>
            <!--end::Form Wizard-->
          </div>
          <div class="col-md-5 col-lg-5 col-sm-12  justify-content-start">
            <div class="pb-3" v-html="$t('withdraw.information', { currencyCode: this.currencyCode })"></div>
            <img src="/assets/media/logos/prime-trust-logo.png" />
          </div>
          <CreateWalletModal :provider="providerType" :crypto="selectedCurrency" :currencies="modalCurrencies" @walletCreated="walletCreatedSuccess"/>
        </div>
      </div>
      <!--end::Create-->
      
      <!--begin::History-->
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">{{ $t('withdraw.history') }}</h3>
        </div>
      </div>
      <div class="card-body">
        <History :selectedCurrency="selectedCurrency" />
      </div>
      <!--end::History-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import CreateWalletModal from './CreateWalletModal.vue';
import History from "./History.vue";
import i18n from '@/config/_i18n'
import Swal from 'sweetalert2'

export default {
  name: "Withdraw",
  data() {
    return {
      amount: null,
      isSubmitting: false,
      currencyPrecision: 2,
      selectedCurrency: null,
      selectedProvider: null,
      approveWithdraw: false,
      confirmData: {
        amount: 0,
        fee: 0,
        amountToPay: 0
      },
    };
  },
  components: {
    Multiselect,
    CreateWalletModal,
    History,
  },
  computed: {
    ...mapState({
      currenciesLoading: (state) => state.finance.currenciesLoading,
      currencies: (state) => state.finance.currencies,
      balanceLoading: (state) => state.finance.balancesLoading,
      balanceByCurrency: (state) => state.finance.balanceByCurrency,
      providersLoading: (state) => state.withdraw.providersLoading,
      providers: (state) => state.withdraw.providers,

      createWalletLabel: function() {
        if(this.selectedCurrency?.crypto) return this.$t('finance.add_wallet');
        return this.$t('finance.add_bank_account');
      },
      providerType: function() {
        if(this.selectedCurrency?.crypto) return 'crypto_currency';
        return 'bank_transfer'
      },
      amountOrQuantity() {
        return this.selectedCurrency.crypto ? this.$t('finance.quantity') : this.$t('finance.amount');
      },
      modalCurrencies() {
        if(this.selectedCurrency?.crypto) return this.currencies.filter(val => val.crypto);
        return this.currencies.filter(val => !val.crypto);
      },
      currencyCode() {
        return this.selectedCurrency?.code || 'TRY';
      },
      bankWalletLabel() {
        return this.selectedCurrency?.crypto ? this.$t("finance.wallet") : this.$t("finance.bank")
      }
    }),
  },
  methods: {
    ...mapActions("finance", ["GET_COMMON_CURRENCIES", "GET_BALANCES_BY_CURRENCY"]),
    ...mapActions("withdraw", ["GET_PROVIDERS", "CREATE_WITHDRAW"]),
    _handleContinue() {
      const amount = parseFloat(this.amount);
      const selectedBank = this.selectedProvider;
      const minPay = parseFloat(selectedBank?.min_pay) || 10;

      if(!this.selectedCurrency.crypto && (amount < minPay)) {
        this.$toast.error(this.$t('custom_messages.must_be_at_least', { field: this.$t('finance.amount'), value: selectedBank.min_pay_formatted }))
        return
      }

      let fee = parseFloat(selectedBank.fee);
      if(this.selectedCurrency.crypto && (selectedBank.fee_type === 'ratio')) {
            fee = (amount*fee);
      }

      this.confirmData.amount = amount;
      this.confirmData.fee = fee;
      this.confirmData.amountToPay = amount - fee;
      this.approveWithdraw = true;
    },
    onChangeCurrency() {
      this.selectedCurrency && this.setUpForCurrency(this.selectedCurrency);
      this.selectedProvider = null;
      
    },
    onChangeProvider() {
      console.log(this.selectedProvider)
    },
    handleCancelWithdraw() {
      this.approveWithdraw = false;
    },
    handleApproveWithdraw() {
      Swal.fire({
        title: this.$t("commons.are_you_sure"),
        text: '',
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.$t("commons.cancel"),
        confirmButtonText: this.$t("commons.yes")
      }).then((result) => {
        if (result.value) {
          this._approveWithdrawal()
        }
      });
    },
    _approveWithdrawal() {
      const fData = {
        amount: this.amount,
        wallet_code: this.selectedProvider.code,
      };
      this.isSubmitting = true;
      this.CREATE_WITHDRAW({ fData, currencyCode: this.currencyCode }).then(() => {
        this.approveWithdraw = false;
        this.amount = null;
        this.selectedProvider = null;
        this.confirmData = {
          amount: 0,
          fee: 0,
          amountToPay: 0
        };
      }).catch(() => {})
      .finally(() => {
        this.isSubmitting = false;
      })
    },
    showCreateWalletModal() {
      if(!this.selectedCurrency) {
        this.$toast.error(this.$t('custom_messages.must_select_field', { field: this.$t('finance.currency')}));
        return;
      }
      this.$store.commit('global/SET_CREATE_WALLET_MODAL', true);
    },
    setUpForCurrency(currency) {
      const { decimal, crypto, code } = currency;
      this.currencyPrecision = decimal || 2;
      this.GET_PROVIDERS({ isCrypto: crypto, currencyCode: code })
      this.GET_BALANCES_BY_CURRENCY({ currencyCode: code }).then(() => {
        this.maxAmount = this.balanceByCurrency.toString().replace(/[^0-9.]/g, '')
      })
    },
    walletCreatedSuccess(args) {
      if(this.selectedCurrency?.code.toLowerCase() === args.currency.toLowerCase()) {
        const {code, crypto} = this.selectedCurrency;
        this.GET_PROVIDERS({ isCrypto: crypto, currencyCode: code })
      }
    }
  },
  created() {
    console.log(i18n.locale)
    this.GET_COMMON_CURRENCIES({ kind: "withdraw" })
      /* .then(() => {
        if(this.currencies.length > 0) {
          this.selectedCurrency = this.currencies[0]
          this.setUpForCurrency(this.currencies[0])
        }
      }) */
  },
};
</script>
